import { initializeApp } from 'firebase/app';
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {

    apiKey: "AIzaSyBiNs0cJcsE7UduhdORWzUZxgqmDzeTwaM",
  
    authDomain: "fireblogs-5776d.firebaseapp.com",
  
    projectId: "fireblogs-5776d",
  
    storageBucket: "fireblogs-5776d.appspot.com",
  
    messagingSenderId: "441045254999",
  
    appId: "1:441045254999:web:e1ceca0adc35ab11eaf412"
  
};
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
export default db;
