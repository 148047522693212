<template>
  <div class="home">
    <h1>Samuhik Todo</h1>
    <p>To Know more Open the About Page 👆👆👆👆</p>
    <ul v-for="todo in todos" :key="todo.id">
      <input type="checkbox" @click="deleteTodo(todo.id)" />
      <span>{{ todo.content }}</span>
    </ul>
    <form @submit.prevent="submitHandler">
      <input type="text" v-model="todo" />
      <button type="submit">Add todo</button>
    </form>
  </div>
</template>

<script>
import db from "../firebase/firebaseInit";
import {
  addDoc,
  collection,
  doc,
  deleteDoc,
  onSnapshot,
  orderBy,
  query,
} from "firebase/firestore";
export default {
  name: "HomeView",
  data() {
    return {
      todos: [],
      todo: "",
      id: 1,
    };
  },
  methods: {
    submitHandler() {
      addDoc(collection(db, "Todos"), {
        id: this.id,
        content: this.todo,
        created: Date.now(),
      });
      this.todos.push({ id: this.id, content: this.todo, created: Date.now() });
      this.id++;
      this.todo = "";
    },
    deleteTodo(id) {
      const todoRef = collection(db, "Todos");
      console.log(doc(todoRef, String(id)));
      deleteDoc(doc(todoRef, String(id)));
    },
  },
  async mounted() {
    const todoCollectionQuery = query(
      collection(db, "Todos"),
      orderBy("created", "desc")
    );
    onSnapshot(todoCollectionQuery, (querySnapshot) => {
      const fbTodos = [];
      querySnapshot.forEach((doc) => {
        console.log(doc.id);
        const todo = {
          id: doc.id,
          content: doc.data().content,
        };
        fbTodos.push(todo);
      });
      this.todos = fbTodos;
    });
    // const querySnapshot = await getDocs(collection(db, "Todos"));
    // querySnapshot.forEach((doc) => {
    //   this.todos.push(doc.data());
    //   console.log(doc.data());
    // });
  },
};
</script>
